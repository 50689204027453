import axios from 'axios';
import { Resolve } from '.';
import config from './config';
import { IFile } from './events';

const url = process.env.VUE_APP_SERVER_PATH;
export const uploadFile = (formData: FormData) => new Promise((resolve: Resolve<IFile>) => {
  axios
    .post(`${url}/files-2`, formData, config(true))
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err.response);
    });
});
export const uploadImage = (formData: FormData) => new Promise((resolve: Resolve<IFile>) => {
  axios
    .post(`${url}/v2/event-images`, formData, config(true))
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err.response);
    });
});

export const getFileById = (file: number | string) => new Promise((resolve: Resolve<IFile>) => {
  axios
    .get(`${url}/files-2/${file}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const deleteFile = (file: number | string) => new Promise((resolve: Resolve<IFile>) => {
  axios
    .delete(`${url}/files-2/${file}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
export const getImageById = (image: number | string) => new Promise((resolve: Resolve<IFile>) => {
  axios
    .get(`${url}/v2/event-images/${image}`, config())
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      resolve(err);
    });
});
