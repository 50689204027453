

































// @ is an alias to /src
import { defineComponent, PropType } from '@vue/composition-api';

import { ICheckbox } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<ICheckbox>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const updateValue = (): void => {
      props.config.value = !props.config.value;
      emit('input', props.config.value);
      if (props.config.onClick) {
        props.config.onClick(props.config.value ? 'on' : 'off');
      }
    };
    return { updateValue };
  },
});
